import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Ecommerce Website Template | Uizard"
    category="Website Templates"
    url="/templates/website-templates/e-commerce-website/"
    metaDescription="Win customers online with Rad Plants, a great e-commerce website prototype template tailor-made for online businesses. Customize now."
    description="
    h2:A great e-commerce website design template for your business
    <br/>
    Win customers online with Rad Plants, a great e-commerce <a:https://uizard.io/templates/website-templates/>website design template</a> tailor-made for your online business. While the template itself features plants, it's easily customizable to your product, whether that be fashion, electronics, jewellery, or even something a little more out of the box. Try out the Rad Plants template and create a phenomenal website for your business today!
    <br/>
    h3:A top-notch e-commerce website prototype template for attracting customers online
    <br/>
    Rad Plants is a top-notch e-commerce website prototype template on Uizard specifically created to attract customers online. The structure of the website is created in such a way that really showcases your products, whilst presenting your branding and providing a seamless shopping experience for your online customers.
    <br/>
    h3:Customizable and fully versatile
    <br/>
    The different parts of this e-commerce website prototype template, including the theme, texts, and images, are all customizable. If you have a pre-defined design system or an inspiration in mind, you can easily import them into Uizard in a few clicks, and this template will reflect your source's fonts, colors, button styles, and more. Begin your e-commerce website <a:https://uizard.io/prototyping/>UI prototyping</a> project today.
    "
    pages={[
      "An e-commerce landing page, divided into different sections, such as categories, featured items, and featured articles",
      'A product page featuring different photos of the product, price, and an "Add to Cart" CTA',
      "A sign-up to newsletter pop-up to collect your customer's emails and generate leads",
    ]}
    projectCode="O4Q0AGM8ZruJw9GpR5YQ"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of E-Commerce Website: home page"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of E-Commerce Website: home page product section"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of E-Commerce Website: sign-up to newsletter pop up"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of E-Commerce Website: product details"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of E-Commerce website template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(relativePath: { eq: "templates/e-shop/e-shop-home.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/e-shop/e-shop-home-section.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/e-shop/e-shop-newsletter-popup.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/e-shop/e-shop-product-detail.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(relativePath: { eq: "templates/e-shop/e-shop-summary.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
